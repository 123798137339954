import {
  TabAnimationSettingsModel,
  TabComponent,
  TabItemDirective,
  TabItemsDirective
} from '@syncfusion/ej2-react-navigations';

import React, {useState, useEffect} from 'react';

import { Card, CardContent, CardHeader } from "@material-ui/core";
import {useSharedLargeCardStyles} from "../shared/sharedStyles";

import {
  DocumentMetadata,
  IApiPerson, IBynderAssetMetaData,
  ImageMetadata, VideoMetadata
} from "services/apiClients/AthenaClient";
import SectionWithGridAndCardList from "../shared/SectionWithGridAndCardList";
import {
  IInfoCard
} from "services/infoCardConversion";
import {
  getDocumentAssetConfig,
  getImageAssetConfig,
  getVideoAssetConfig
} from "./gridConfigSettings/asset";
import ChangeViewButton from "../../dashCore/ChangeViewButton";
import {useGlobalState} from "../../../app/state/useGlobalState";
import {hasPermission} from "../../../app/security/utils/securityUtil";
import Carousel from "../../../app/Carousel/Carousel";
import _ from "lodash";
import Axios from 'axios';
import LinkToBynder from 'app/bynder/ui/LinkToBynder';

export default function PersonAssetCard(props: { person?: IApiPerson }) {
  const sharedClasses = useSharedLargeCardStyles();
  const gs = useGlobalState();
  const [listViewOn, setListViewOn] = useState<boolean>(true);
  const [images, setImages]= useState<ImageMetadata[]>([]);
  const [documents,setDocuments] = useState<DocumentMetadata[]>([]);
  const [videos,setVideos] = useState<VideoMetadata[]>([]);
  const districtNumber = props.person?.district?.split('(')[1]?.split(')')[0] ?? '';
  const bynderLink = props.person?.lastName?.trim() + '_' + props.person?.firstName?.trim() + '_' + districtNumber?.trim() + '_' + props.person?.id;
  const personId = props.person?.id?.toString() ?? '';

  let tabCtrl: TabComponent | null;

  function setTabCtrl(instance: TabComponent) {
    tabCtrl = instance;
    if (tabCtrl !== null) {
      tabCtrl.refreshActiveTab();
    }
  }

  useEffect(() => {
    getBynderAssets();
  }, []);

  async function getBynderAssets() {

    const headers = {'Access-Control-Allow-Origin' : '*'};
 
    const bynderAssetRetrieveUrl = `https://qa-hpbynderapi.azurewebsites.net/api/GetBynderAssetByKeyword/?keyword=${personId}&code=AsjqG1Ley-7PXoINAMlmdQBNQKfrTy-Tohq0Syelenb8AzFunPAR6g%3D%3D`;

    const bynderAssets = await Axios.post(bynderAssetRetrieveUrl, {headers: headers});

    const images = bynderAssets.data.filter((asset: IBynderAssetMetaData) => isBynderImage(asset)) as IBynderAssetMetaData[];
    const documents = bynderAssets.data.filter((asset: IBynderAssetMetaData) => isBynderDoc(asset)) as IBynderAssetMetaData[];
    const videos = bynderAssets.data.filter((asset: IBynderAssetMetaData) => isBynderVideo(asset)) as IBynderAssetMetaData[];

    const newImages: ImageMetadata[] = [];
    const newDocuments: DocumentMetadata[] = [];
    const newVideos: VideoMetadata[] = [];

    images.forEach(image => {
      const newImageMetaData: ImageMetadata = {
        height: image.height,
        width: image.width,
        thumbnailUrl: image.thumbnails?.mini,
        name: image.name,
        sizeInBytes: image.fileSize,
        contentType: image.type,
        url: image.downloadLink.s3_file,
        modifiedOn: image.dateModified ? new Date(image.dateModified) : undefined,
        init: function (data?: any): void {
          throw new Error('Function not implemented.');
        },
        toJSON: function (data?: any) {
          throw new Error('Function not implemented.');
        }
      };
      newImages.push(newImageMetaData);
    });

    setImages(newImages);
    documents.forEach(doc => {
      const newDocMetaData: DocumentMetadata = {
        name: doc.name,
        sizeInBytes: doc.fileSize,
        contentType: doc.type,
        url: doc.downloadLink.s3_file,
        thumbnailUrl: doc.thumbnails?.mini,
        modifiedOn: new Date("2024-08-15T20:00:02Z"),
        init: function (data?: any): void {
          throw new Error('Function not implemented.');
        },
        toJSON: function (data?: any) {
          throw new Error('Function not implemented.');
        }
      };
      newDocuments.push(newDocMetaData);
    });
    setDocuments(newDocuments);

    videos.forEach(video => {
      const newVideoMetaData: VideoMetadata = {
        name: video.name,
        thumbnailUrl: video.thumbnails?.mini,
        sizeInBytes: video.fileSize,
        contentType: video.type,
        modifiedOn: new Date("2024-08-15T20:00:02Z"),
        url: video.downloadLink.s3_file,
        init: function (data?: any): void {
          throw new Error('Function not implemented.');
        },
        toJSON: function (data?: any) {
          throw new Error('Function not implemented.');
        }
      };
      newVideos.push(newVideoMetaData);
    });
    setVideos(newVideos);
  }

  function isBynderImage(asset: IBynderAssetMetaData) {
    if(asset && asset.property_Asset_Type && asset.property_Asset_Type[0] === 'Photo' || asset && asset.property_Asset_Type && asset.property_Asset_Type[0] === 'Graphic') return true;
  }

  function isBynderVideo(asset: IBynderAssetMetaData) {
    if(asset && asset.property_Asset_Type && asset.property_Asset_Type[0] === 'Video') return true;
  }

  function isBynderDoc(asset: IBynderAssetMetaData) {
    if(asset && asset.property_Asset_Type && asset.property_Asset_Type[0] === 'Document') return true;
  }

  function changeView() {
    setListViewOn(!listViewOn);
  }

  const imageCards = [] as IInfoCard[]; //convertProjectHistory(items);
  const docCards = [] as IInfoCard[];
  const imageConfig = getImageAssetConfig();
  const docConfig = getDocumentAssetConfig();
  const videoConfig = getVideoAssetConfig();

  console.log('images', images);
  const animation = {'next':{'effect': 'None'},
                      'prev':{'effect': 'None'}} as TabAnimationSettingsModel;

  const imagesTabContent = () =>
      (<SectionWithGridAndCardList listView={listViewOn}
                                  items={images}
                                  columnDirectives={imageConfig.columnDirectives}
                                  title={imageConfig.title}
                                  showTitle={imageConfig.showTitle}
                                  allowAdd={imageConfig.allowAdd}
                                  allowRemove={imageConfig.allowRemove}
                                  allowExcelExport={false}
                                  allowFiltering={true}
                                  toolbarOptions={imageConfig.toolbarOptions}
                                  infoCards={imageCards}/>);

  const documentTabContent = () =>
      (<SectionWithGridAndCardList listView={listViewOn}
                                   items={documents}
                                   columnDirectives={docConfig.columnDirectives}
                                   title={docConfig.title}
                                   showTitle={docConfig.showTitle}
                                   allowAdd={docConfig.allowAdd}
                                   allowRemove={docConfig.allowRemove}
                                   allowExcelExport={false}
                                   allowFiltering={true}
                                   toolbarOptions={docConfig.toolbarOptions}
                                   infoCards={docCards}/>);

  const videoTabContent = () =>
      (<SectionWithGridAndCardList listView={listViewOn}
                                   items={videos}
                                   columnDirectives={videoConfig.columnDirectives}
                                   title={videoConfig.title}
                                   showTitle={videoConfig.showTitle}
                                   allowAdd={videoConfig.allowAdd}
                                   allowRemove={videoConfig.allowRemove}
                                   allowExcelExport={false}
                                   allowFiltering={true}
                                   toolbarOptions={videoConfig.toolbarOptions}
                                   infoCards={docCards}/>);

  const canContrib = hasPermission(['Contributor'], gs.userRoles);

  function handleCarouselClose() {
    _.noop();
  }

  return (
      <>
        <Card id="assets-module" className={sharedClasses.defaultCard}>
          <CardHeader
              title={'Assets'}
              action={<ChangeViewButton showListIcon={listViewOn} onClick={changeView}/>}/>
          <CardContent style={{display: 'flex'}}>
          <div className="w-full text-right">
              <LinkToBynder bynderLinkMetaProperty={'Dash_People_Unique_Identifier'} bynderLinkMetaPropertyValue={bynderLink} />
            </div>

            <Carousel images={images.map(x => x.url as string)}
                      imageNames={images.map(x => x.name as string)}
                      onClose={handleCarouselClose} />
            
            <TabComponent ref={(r) => setTabCtrl(r as TabComponent)}
                          width={'100%'} animation={animation}
                          heightAdjustMode={'Content'}>
              <TabItemsDirective>
                <TabItemDirective header={{text: 'Images'}} content={imagesTabContent}/>
                <TabItemDirective header={{text:'Documents'}} content={documentTabContent} visible={canContrib}/>
                <TabItemDirective header={{text: 'Videos'}} content={videoTabContent}/>
              </TabItemsDirective>
            </TabComponent>

          </CardContent>
        </Card>
      </>

  )
}