import AthenaAuthorize from '../../security/ui/AthenaAuthorize'
import { ExitToApp } from '@material-ui/icons'
import React from 'react'

function LinkBynder(props: {
  bynderLinkMetaPropertyValue?: string
  bynderLinkMetaProperty?: string
}) {
  const bynderUrl = `https://marcom.henselphelps.com/media/?resetsearch=&burlie&field=metaproperty_${
    props.bynderLinkMetaProperty
  }&value=${props.bynderLinkMetaPropertyValue?.replaceAll(' ', '_')}`

  return (
    <AthenaAuthorize allowedRoles={'contributor'}>
      <a href={bynderUrl} target={'_blank'}>
        <ExitToApp style={{ color: '#999' }} />
        <img
          style={{ height: '22px' }}
          src="https://www.bynder.com/static/img/svg/bynder-logo-blue.svg"
          alt="bynder logo"
        />
      </a>
    </AthenaAuthorize>
  )
}

export default LinkBynder
